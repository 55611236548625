const categories = [

  {
    title: "Community & dating sites",
    description:
      "These are just a few of the most popular / buzzing feedism-focused community sites. My experience is that it's usually about the people you follow/interact with— both Squeak and I have had uncomfortable experiences with some people in the community online, but generally, given time and enough conversation, it's easy to workout if someone's decent or not. — Bubble \n\nThere are a bunch of sites (both community and dating) we've not mentioned here on account of very little experience with them, so we'd really appreciate any contributions!",
    items: [
      {
        image: "ff_header_image.png",
        title: "Fantasy Feeder",
        description:
          "First off: this is where Squeak and I met— so it's forever in our hearts! FF describe themselves as a fat-positive community and for people who either want to feed others or who themselves want to get fatter.",
        pros: [
          "Great for meeting people",
          "Active forums are a great way to explore feedism",
          "Many active members",
          "Great for reading and posting stories",
        ],
        cons: [],
        context: "FREE / paid",
        link: {
          "Visit FF": "https://fantasyfeeder.com/",
        },
      },
      {
        image: "curvage_header_image.png",
        title: "Curvage",
        description:
          "Curvage call themselves 'the world’s LARGEST community dedicated to Curves'. The site mostly follows a classic forum layout, with most threads dedicated to curvy woman. Many popular people in the community are on Curvage, with some selling their clips.",
        pros: [
          "High quality photos",
          "Proper rich text editing (great for stories)",
          "Up-to-date threads on many prominent members of the community as well as celebrities",
        ],
        cons: [],
        context: "FREE / paid content",
        link: {
          "Visit Curvage": "https://curvage.org/",
        },
      },
      {
        image: "feabie_header_image.png",
        title: "Feabie",
        description:
          "Feabie is a social network and dating site for feeders, feedees, fat admirers and BBW/BHM— though with a self proclaimed focus on heterosexuals. There's a lot to like and the community is very active. From my experience, people share more about their lives like you would on other social networks, so it's easier to start conversation and chats tend to be more broad reaching than other sites, where conversations are often solely kink focused.",
        pros: [
          "Decent support from site maintainers",
          "Busy main feed",
          "Great search for finding people",
          "App is great, especially if you use the built-in chat",
        ],
        cons: [],
        context: "FREE / paid",
        link: {
          "Visit Feabie": "https://www.feabie.com/",
          "Feabie's fab Feedism FAQ":"https://www.feabie.com/Home/FAQ"
        },
      },
      {
        image: "tumblr_header_image.png",
        title: "Tumblr",
        description:
          "Tumblr has clamped down on some content types, but it remains a great place to both connect and enjoy people's feedism journeys.",
        pros: [
          "Particularly good for male feedees and therefore male feedee content",
          "Great for FFAs",
          "Benefits of it being a better known platform",
        ],
        cons: [
          "Stricter rules",
          "Cannot stop minors visiting profiles",
        ],
        context: "FREE / paid",
        link: {
          "Visit Tumblr": "https://www.tumblr.com/",
        },
      },
      {
        image: "dimensions_header_image.png",
        title: "Dimensions Magazine",
        description:
          "Dimensions Magazine call themselves a 'Size Acceptance Community'. As community member RedShellBlueShell put it: 'Discovering Dimensions 10 years ago helped me accept myself and, in so doing, helped me realize more opportunities to promote joy and compassion in life. I only have one regret: not joining up sooner!'",
        pros: [
          "'Especially good reminiscing about the early days', as community member RVGleason put it!",
          "Great for stories content!",
        ],
        cons: [],
        context: "FREE",
        link: {
          "Visit Dimensions Magazine": "https://www.dimensionsmagazine.com/",
        },
      },
      {
        image: "drfeeder_header_image.png",
        title: "Dr Feeder",
        description:
          "UPDATE: Appears to be down at the moment! \n\nMany remember Dr Feeder as being one of those first sites they came across when they were exploring weight gain. The site features advice, some stories and a gallery of people doing 'the wall test'. Dr Feeder now graces Fantasy Feeder forums with advice too!",
        pros: [
          "Feel good place to get advice",
          "Wall test gallery is updated every now and then",
        ],
        cons: [],
        context: "FREE",
        link: {
          "Visit Dr Feeder's site (currently down)": "http://askdrfeeder.dyndns.org/",
          "Visit Dr Feeder on FF": "https://fantasyfeeder.com/profiles/activity?userId=20530",
        },
      },
    ],
  },

  {
    title: "Content focused sites / pages",
    description:
      "Here are our picks for our favourite sites for feedism content. Exploring these has definitely helped us explore what excites us most! A couple of the community sites above fit in this category— especially Curvage, so check those out too! \n\nTop tip: Usual content sites are great for feedism stuff too— though it's easier to find your favourite models than to search using feedism lingo!",
    items: [
      {
        image: "stufferdb_header_image.png",
        title: "StufferDB",
        description:
          "StufferDB generally has most of the freely available content from prominent members of the feedism community. Most content is tagged well and is searchable, although where we are in Europe, loading times can be a bit slow.",
        pros: [
          "Great for finding 'that one video' that you remember seeing once but can't find!",
          "Includes content from no longer active members of the community— though there are some moral gray areas here for some.",
          "One of the best places for before & after collages",
          "Frequently updated with new & old found content",
        ],
        cons: [],
        context: "FREE",
        link: {
          "Visit StufferDB": "http://stufferdb.com",
        },
      },
      {
        image: "wgbeforeafter_header_image.png",
        title: "r / wgbeforeafter",
        description:
          "Weight Gain Before and After is another great active Reddit with a bunch of exciting before and afters! Worth noting that it's female only.",
        pros: [
          "All the benefits of being on Reddit, such as accessibility and search",
          "Includes more 'naughty' content",
        ],
        cons: [],
        context: "FREE",
        link: {
          "Visit r / wgbeforeafter": "https://www.reddit.com/r/wgbeforeafter/",
        },
      },
      {
        image: "rstuffers_header_image.png",
        title: "r / stuffers",
        description:
          "Great Reddit. Almost always active and especially great for woman who just want to dabble in the community with the occasional photo/before and after photo.",
        pros: [
          "All the benefits of being on Reddit, such as accessibility and search",
          "Includes more 'naughty' content",
          "Great for teasing and discussion— with some tagging their posts as teasing friendly!",
        ],
        cons: [],
        context: "FREE",
        link: {
          "Visit r / Stuffers": "https://www.reddit.com/r/Stuffers/",
        },
      },
      {
        image: "googleimages_header_image.png",
        title: "Google Images",
        description:
          "Google images is a great way to get the latest content— and from sites you don't usually visit, as you can make a searches like 'weight gain' and then filter by date!",
        pros: [
          "Great for finding new content you haven't seen yet",
          "Google search uses machine learning to tag images, so you can search quite broadly",
          "Top tip: use the Google Lens icon after finding an image you like, to find similar ones!"
        ],
        cons: [],
        context: "FREE",
        link: {
          "Google image search for 'weight gain' in the past week": "https://www.google.com/search?q=weight%20gain&tbm=isch&tbs=qdr:w",
        },
      },
      {
        image: "bigcuties_header_image.png",
        title: "Big Cuties",
        description:
          "Big Cuties is a model agency based in Canada. There are a fair few BBWs that each have their sub-site where you can get their exclusive content",
        pros: [
          "Great for BBW content",
          "Good support",
          "Seem to be one of the most fair to models",
          "Large catalogue",
        ],
        cons: [],
        context: "~ $20 / m",
        link: {
          "Visit Big Cuties": "https://www.bigcuties.com/",
        },
      },
      {
        image: "onlyfans_header_image.png",
        title: "OnlyFans",
        description:
          "Only Fans has some brilliant creators who often can't be found anywhere else!",
        pros: [
          "Nice prices",
          "Good interaction with creators",
        ],
        cons: ["Hard to search for creators as feedism terms seem to be shadow banned, we recommend using Google and including 'Only Fans' in your search"],
        context: "~ $10 / m",
        link: {
          "Visit OnlyFans": "https://onlyfans.com/",
        },
      }, 
      {
        image: "taylormadeclips_header_image.png",
        title: "Taylor Made Clips",
        description:
          "Taylor Made Clips has a diverse range of weird and wonderful fetish clips— often related to growth and expansion.",
        pros: [
          "Actors are very entertaining",
          "Lots of blueberry inflation content— more than any other platform we've found",
          "If you're up for a challenge, you can enjoy finding the right version of Adobe flash player in order to use the request form 😅",
        ],
        cons: [],
        context: "~ $10 / clip",
        link: {
          "Visit Taylor Made Clips": "https://www.taylormadeclips.com",
        },
      },
    ],
  },

  {
    title: "Fake bellies",
    description:
      "A fake belly can be an excellent way to have all the fun of having a larger belly without the commitment. Many of us pad with pillows and trying a fake belly is really just an enhanced version of this. \n\nPersonally, I was sceptical over whether the cost was worth it - until I tried one for myself and felt how exciting it was to feel that extra weight and smooth roundness out in front of me. \n\nSome people are put off by the marketing of these products as 'pregnant'. If that's an issue for you, I can say that fake bellies can be used with clothes to create a bulky shape and allow you to feel what it's like to carry extra weight and do not necessarily have to show that smooth roundness. They're excellent fun to play with alone, with a partner and even to wear when venturing out in public. — Squeak",
    items: [
      {
        image: "siliconebelly_header_image.png",
        title: "Silicone",
        description:
          "Affordable silicone bellies all seem to be the same model that come usually drop-shipped from China. There are a few (usually film prop) companies about that make them at varying levels of customised fit, but they're usually very pricey. \n\nThe more affordable ones if gotten from somewhere like AliExpress, can be found in 6 or so variants, from 1 - 5KGs and sometimes different skin tones. For our last one, we spent a little extra at Amazon for the convenience of quick postage and return guarantee— though drop-shipping sellers such as those on Amazon usually only stock relatively few smaller sizes.",
        pros: [
          "They are heavy and give the sensation of being heavier",
          "They are squishy and can be pinched like a real tummy",
          "Some stick onto the skin and blend into your own body better than some others",
        ],
        cons: [
          "We've found the stickiness can become itchy if you are wearing it for a long time",
          "There may be a gap at the top which has to be pressed back down (Top tip: wear shapewear underwear to hold it in place for longer)",
          "The larger sizes can be costly",
        ],
        context: "~ $50 - $300",
        link: {
          "Our favourite silicone belly (Amazon)": "https://amzn.to/3Uyor88",
          "Search on AliExpress":"https://www.aliexpress.com/wholesale?catId=0&SearchText=silicone+belly",
        },
      },
      {
        image: "foambelly_header_image.png",
        title: "Foam",
        description:
          "Foam bellies are great for creating the shape of a larger gut. Most of the affordable options appear to be one of 3 designs. Like silicone bellies, more sizes and skin tones can be found on sites like AliExpress presumably more directly from the factory that makes them.",
        pros: [
          "Foam is a more breathable so it's more comfortable for longer wear times.",
          "Some come with the foam and 'bag' separate, so you can choose what sort of shape belly you want",
        ],
        cons: [
          "Don't give the sensation of being heavier like silicone bellies do",
          "Harder to mould to fit your body— unless you opt for a sticky inside which is harder to find",
          "Images are misleading on some sites— whilst only a little below the provided measurements buried in the description, one we bought was definitely not the size shown in the product listing.",
        ],
        context: "~ $30 - $100",
        link: {
          "What we got (Amazon)": "https://amzn.to/3r2w9dj",
        },
      },
    ],
  },
  {
    title: "Stuff we wish we knew when we started out",
    description:
      "We're a young, cis, straight couple— so we've still a great deal to learn. We also really want to add more here specifically around the experience of LGBTQ+ community members and people of different cultures.",
    items: [
      {
        image: "safety_header_image.png",
        title: "Safety",
        description:
          "Online safety is understood by most nowadays— but there are still things to be wary of in the community.",
        pros: [
            "Content creators make clear where they draw the line on privacy— check bios and rules before sharing.",
        ],
        cons: [
          "Doxxing forces and has forced excellent people out of the community — so much of what allows us as a community to flourish is trust, so always consider consequences of sharing even if you mean well. No doubt if you're here you already know this, but it's always good to remind in case!",
        ],
        context: "",
        link: {
        },
      },
      {
        image: "meetingpeople_header_image.png",
        title: "Meeting people",
        description:
          "In our experience, meeting people in the community is relatively similar to meeting people from elsewhere online, and indeed offline — make sure your expectations are aligned. Are you meeting up just for a chat — or for a feeding session?",
        pros: [
            "Obvious one: like meeting anyone you don’t really know yet, make sure you put your safety first.",
            "Take your time to make decisions and never allow someone to pressure you into making choices"
        ],
        cons: [
          "We have both experienced receiving unwanted messages online both from inside and out of the community. We know that it can feel like an infringement of your personal space. Hopefully community sites adopt better automated filtering and reporting— in the meantime, we hope that you'll feel confident in ignoring or blocking anyone you feel uncomfortable talking with",
        ],
        context: "",
        link: {
        },
      },
      {
        image: "pressure_header_image.png",
        title: "Pressure",
        description:
          "We’ve experienced people in the community come under pressure from others within the same space. In particular, feedees and gainers who post content tracking their body often seem to face demands for them to gain more and more, faster and faster. \n\nWe think it’s super important for everyone to feel comfortable in the community. If you’re considering posting about your gain online, we hope you don’t put pressure on yourself or take it from others. It’s your body and you run the show. \n\nThe saying is fat and happy, after all",
        pros: [
        ],
        cons: [],
        context: "",
        link: {
        },
      },
      {
        image: "mentalhealth_header_image.png",
        title: "Feeling shame & mental health",
        description: "",
        pros: [
            "It’s normal to feel shame about your fetish(es) — heck, that shame is the biggest turn on sometimes (definitely the case for us!)",
            "We’ve found spending time experimenting has helped us discover more about, and become more comfortable with ourselves and each other.",
            "Chatting to others on the above community websites can help, as it’s reassuring to know that you are not the only one."
        ],
        cons: [],
        context: "",
        link: {
        },
      },
    ],
  },
  {
    title: "'Wider' research",
    description:
      "There are some great resources we've found that have helped us to understand why we like bellies so damn much 😅— if you've seen anything, please send it our way!",
    items: [
      {
        image: "media_header_image.png",
        title: "Feedism in the media",
        description:
          "Perhaps understandably, feedism gets a pretty bad rap in the media. However, some say any exposure is good exposure and this publicity has brought feeders to the attention of some people who didn’t know there was a word for the feelings they had. \n\nWe've heard about community members getting invasive messages from the media— likely on slow news weeks— but this seems infrequent.",
        pros: [],
        cons: [],
        context: "",
        link: {
        },
      },
      {
        image: "psychology_header_image.png",
        title: "Psychology",
        description:
          "From what we've found, papers and articles about feedism tend to cite the same limited sources or just make presumptions— seemingly without even having attempted respectful contact with someone in the community. \n\nTo be fair though, this is a wild and varying fetish. There seem to be so many different kinks and combinations— we're not even close to sure what makes us feel how we do! \n\nPlease contribute anything you've found!",
        pros: [],
        cons: [],
        context: "",
        link: {
          "Eating, Weight Gain, and Sexual Pleasure by Kathy Charles and Michael Palkowski - the creators of Horngry":"https://g.co/kgs/4NS78s",
          "Food, feeding and female sexual arousal by Terry, Lesley L":"https://opus.uleth.ca/bitstream/handle/10133/2510/TERRY_LESLEY_MSC_2010.pdf?sequence=3&isAllowed=y"
        },
      },
      // {
      //   image: "history_header_image.png",
      //   title: "History",
      //   description:
      //     "Info about history",
      //   pros: [],
      //   cons: [],
      //   context: "",
      //   link: {
      //   },
      // },
    ],
  },
  {
    title: "We ❤️",
    description:
      "These are 💻 sites / 📖 blogs / 📄 pages / 🧑‍💼 people that we particularly enjoy for their quality content and / or contribution to the community.",
    items: [
      {
        image: "",
        title: "Community members & models",
        description:
          "Can't lie— we deffo have some all time faves in the community! \n\n* = no longer active in the community, but still amazing!",
        pros: [],
        cons: [],
        context: "",
        link: {
          "ScaryBabe/Reiinapop": "https://curvage.org/forum/index.php?/profile/47150-reiinapop/",
          "Mochii Babii":"https://linktr.ee/mochiibabii",
          "Good Girl, Grow":"https://goodgirlgrow.tumblr.com/",
          "DanPizzaBoy":"https://danpizzabelly.tumblr.com/",
          "Candii Kayn*": "https://www.reddit.com/user/Candii_Kayn/",
          "Chubby Girl Videos (CGV)*": "https://stufferdb.com/index.php?/category/200",
          
        },
      },
      {
        image: "tumblr_header_image.png",
        title: "Blogs",
        description:
          "Here are some of our favourite blogs!",
        pros: [],
        cons: [],
        context: "",
        link: {
          "oh no! you found my kink blog (men, carton illustration scenarios)":"https://squishyslush.tumblr.com/",
          "Blogartus (male feedees + submissions)": "http://blogartus.tumblr.com",
          "Soft Feedism":"https://cute-feedism-things.tumblr.com/",
          "You Got Fat (Female weight gain)":"https://yougotfat-bbw.tumblr.com/",
          "soft bellies and thick thighs":"https://soft-feederism.tumblr.com/",
          "feedee suggestions (lovely writing)":"https://tummywrites.tumblr.com/",

        },
      },
      {
        image: "horngry_header_image.png",
        title: "Horngry",
        description:
          "Horngry deserves it's own card here. It was made by creator and amazing community member TenderLovingCares, who had some excellent podcast discussions, did beautiful illustrations and thoughtful writing.",
        pros: [],
        cons: [],
        context: "",
        link: {
          "Horngry: podcast, webzine + more*":"http://www.horngrymagazine.com/",

        },
      },
    ],
  },
  {
    title: "Feedism glossary",
    description:
      "Here's a round up of some of the most frequent terms used by the community: \n\n❤️ We think it’s worth pointing out that you absolutely can identify with any of these in any amount— or not at all! \n\nAlvinolagnia = Being particularly attracted to the stomach area and/or belly button \n\nFA = Fat Admirer \n\nFeedee = Someone who is aroused by being fed and gaining weight. They can be fed in person or some feedees take payments online to fund their eating and consider this similar to being fed \n\nGainer = Similar to feeder, but we reckon the main difference is that gaining Is the actual pursuit of putting on weight, whereas we’ve seen people identify as feedees but they’re not always trying to pile on the pounds \n\nFeeder (or encourager) = Someone who is aroused by feeding and fattening up someone else. This can also be in person or online \n\nBBW = Big Beautiful Woman \n\nBHM = Big Handsome Man \n\nSSBBW = Super-sized Big Beautiful Woman \n\nMPreg = Male Pregnancy \n\nVore= Sexual fantasises about being consumed by another person/being or consuming another person/being \n\nRp = Roleplay, many people in the community enjoy pretending to fulfil their fantasies. This can be through exchanging messages where the participants take on roles and play out feeding and gaining. Roleplay also often refers to when a content creator puts out videos, images etc. of them pretending to be in a certain scenario \n\nStuffing = Eating a lot of food to achieve a big full belly. It can be done to gain weight, but not always \n\nWater bloating/chugging = Drinking a lot of water/other liquids to expand the tummy \n\nSoda bloat = Like a water bloat, but with soda (fizzy drinks) \n\n⚠️ Like doing anything too much and too fast, stuffing and water bloating can be very dangerous. Please always take it slow and do your research beforehand.",
    items: []
  }
];

export default categories;
