import "./ItemTile.css"

const ItemTile = (props) => {

    return (

        <div id="item-tile">

       
            <div className="item-image-container">
                {props.image.length > 0 && <img alt={""} className="item-image" src={require('../images/' + props.image)} />}
            </div>

            <div className="item-top-aligner">
                    <h2 className="item-title">{props.title}</h2>
                    {props.context.length > 0 && <p className="item-context">{props.context}</p>}
            </div>

            

            <div className="item-padding">

                

                
                

                <p className="item-description">{props.description}</p>

                <ul className="item-pros">

                    {props.pros.length > 0 && props.pros.map((pro, index) => (
                        <li key={"pros_" + index}>
                            {pro}
                        </li>
                    ))}

                </ul>

                <ul className="item-cons">

                    {props.cons.length > 0 && props.cons.map((pro, index) => (
                        <li key={"cons_" + index}>
                            {pro}
                        </li>
                    ))}

                </ul>

                <div>{props.children}</div>


                {Object.keys(props.link).length > 0 && Object.keys(props.link).map((link, index) => (

                    <a key={"link_" + index} className="item-link" href={props.link[link]}>{link}</a>
                        
                ))}



                

            </div>
            
        </div>



    );

}

export default ItemTile; 