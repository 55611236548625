import "./IntroductionTile.css"
// import intro_icon from '../images/intro_icon_2.svg';
import AnimatedIntroIcon from "./AnimatedIntroIcon";

const IntroductionTile = (props) => {

    return (

        <div id="introduction-tile">

            {/* <img alt="outline drawing of our bellies"  className="introduction-image" src={intro_icon}></img> */}

            <AnimatedIntroIcon />

            <h1 className="introduction-title">Hello! We're Bubble & Squeak!</h1>

            <p className="introduction-description">We've set up this site because there's a bunch of stuff we wish we knew when we first started out and some we're only finding now! We'll be adding stuff over time and we'd love to add anything you've found too! {'\n'}Go to <a href="https://forms.gle/TaCTtkEbq2SXAKcd9">this form</a> if you've any {'\n'}ideas or submissions!</p>

            <div className="introduction-children">{props.children}</div>
            
        </div>



    );

}

export default IntroductionTile; 