import "./CategoryTile.css"

const CategoryTile = (props) => {

    return (

        <div className="category-tile">

            <div className="anchor" id={props.id}></div>


            <h1 className="category-title">{props.title}</h1> 

            

            {/* {props.description.map()} */}

            <p className="category-description">{props.description}</p>

            <div className="category-children">{props.children}</div>
            
        </div>



    );

}

export default CategoryTile; 