import "./CategoryChips.css"

const CategoryChips = (props) => {

    const toKebabCase = str =>
        str &&
        str
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map(x => x.toLowerCase())
            .join('-');

    return (

        
        <div id="category-chips">

            {props.categories.map((category, index) => (

                <a key={"category_"+ index} href={"#" + toKebabCase(category.title)} className="category-chip">{category.title}</a>

            ))}

            
        </div>



    );

}

export default CategoryChips; 