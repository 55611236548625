import "./FooterTile.css"

const FooterTile = (props) => {

    return (

        <div id="footer-tile">

            <p className="footer-info">*We're directing to site searches to try to avoid broken links.<br></br>When it makes sense to, we might use affiliate links to pay for the domain and hosting of this site.</p>

            <div className="footer-children">{props.children}</div>
            
        </div>



    );

}

export default FooterTile; 