import "./AnimatedIntroIcon.css"

const AnimatedIntroIcon = (props) => {


  return (

    <div>

      <div id="thing">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 507.6 285.4">
          <g data-name="Layer 2">
            <g data-name="Layer 1">
              <path
                className="cls-1"
                id="shape-1"
                d="M242.9 99c-2.4-12.9-7.8-29.4-16.9-45.6c-6.6-11.7-15.1-23.2-25.8-33"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-1"
                id="shape-2"
                d="M136.2 240.9c47.4 2.6 83.5-24.8 100.2-48.5"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-2"
                id="shape-3"
                d="M189.1 265.1c3.3-8.3 5.1-19.4 5.4-33.5"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-2"
                id="shape-4"
                d="M124.3 219c7.9 13.2 9.5 27.7 4.6 43.5"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-2"
                id="shape-5"
                d="M209.7 151.6c-1.9 2.4-1.8 10.6-0.7 12.8"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-2"
                id="shape-6"
                d="M23.2 261.7c-21-55.7-17.1-88.6-10.1-128.6c7-40 29.1-82 28.4-112.6"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"
              />
              <path
                className="cls-1"
                id="shape-7"
                d="M364.9 240.7c-42.8-13-73.3 9-96.5-3.5c-25.5-13.9-37.1-48.7-34.3-73.8c3.7-32 15-124.7 73.1-153.1"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-2"
                id="shape-8"
                d="M295.8 277.9c-3.4-9.1-5.2-18.4-5.6-33.7"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-2"
                id="shape-9"
                d="M375.7 227.3c-8.1 14.5-9.7 30.4-4.7 47.8"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-2"
                id="shape-10"
                d="M291.6 158.4c1.9 1.6 1.7 7.4 .5 9"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-2"
                id="shape-11"
                d="M477.6 186.2c18-41.2 14.7-65.6 8.7-95.2c-6.1-29.6-25.1-60.8-24.4-83.5"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
                fillOpacity="0"

              />
              <path
                className="cls-3"
                id="shape-12"
                d="M498.2 275.1c8.4-51.5-14.1-93.2-14.1-93.2"
                strokeMiterlimit="3.7"
                strokeWidth="15px"
                strokeLinecap="round"
                stroke="#1c65aa"
              />
            </g>
          </g>
        </svg>
      </div>

    </div>

  );


}

export default AnimatedIntroIcon;