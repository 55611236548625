import "./App.css";
import NavBar from "./components/NavBar";
import CategoryTile from "./components/CategoryTile";
import ItemTile from "./components/ItemTile";

import categories from "./data/db"
import IntroductionTile from "./components/IntroductionTile";
import FooterTile from "./components/FooterTile";
import CategoryChips from "./components/CategoryChips";

const App = () => {

  const toKebabCase = str =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-');

  return (
    <div className="App">
      <NavBar />

      <IntroductionTile />

      <CategoryChips 
        categories={categories}
      
      />

      {categories.map((category, index) => (

        <CategoryTile
          key={"category_" + index}
          id={toKebabCase(category.title)}

          title={category.title}
          description={category.description}
        >

          {category.items.length > 0 && category.items.map((item, index) => (
            
            <ItemTile

              key={"item_" + index}
                      
              image={item.image}
              title={item.title}
              description={item.description}
              pros={item.pros}
              cons={item.cons}
              context={item.context}
              link={item.link}
              linkText={item.linkText}
            
            />

          ))}
          
          
        </CategoryTile>

      ))}

      <FooterTile />

          


    </div>
  );
};

export default App;
