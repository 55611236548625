import './NavBar.css'
import logo from '../images/logo.svg';

const NavBar = () => {

    return (

        <nav id="nav-wrapper">
            <div id="nav-width">
                <img alt="logo icon" id="logo-icon" src={logo} />
                <h1 id="logo-text">feedism stuff</h1> 

                <a className="nav-link" href="https://forms.gle/chG2yADuLfkrrS9s7">Say hi</a>
            </div>
            
        </nav>

    );

}

export default NavBar; 